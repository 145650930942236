import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable, Subject } from 'rxjs';
// import { CommonAlertService } from '../Helpers/common-alert.service';
// import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
//import { ResponseContentType, ResponseType, Http } from '@angular/http';
import { ResponseModel } from '../Models/ResponseModel';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Credentials': 'false',
    'Access-Control-Allow-Headers': 'Content-Type',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Origin': '*',
  });

  apiendpoint: string = environment.apiendpoint;
  reportEndpoint: any = environment.reportEndpoint;
  errorMessage = new Subject<string>();
  // reportBaseUrlPdf: any =  environment.reportBaseUrlPdf;

  constructor(
    private httpClient: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,

    /* private alertService: CommonAlertService,private loader:NgxSpinnerService,*/ private route: Router
  ) {
    const aa = this.route.url;
    if (isPlatformBrowser(this.platformId)) {
      this.apiendpoint =
        window.location.href.split('/').slice(0, 3).join('/') + '/';
      this.reportEndpoint =
        window.location.href.split('/').slice(0, 3).join('/') + '/EzReports/';
    }
  }

  GetData(url) {
    return this.httpClient
      .get(this.apiendpoint + url)
      .pipe(catchError((error) => this.handleError(error)));
  }

  GetDataWithHeaders(
    url,
    customHeader: { name: string; value: string }[] = []
  ) {
    let customHeaders = this.headers;
    if (customHeader.length > 0) {
      customHeader.forEach((header) => {
        customHeaders = customHeaders.append(header.name, header.value);
      });
    }

    return this.httpClient
      .get(this.apiendpoint + url, { headers: customHeaders })
      .pipe(catchError((error) => this.handleError(error)));
  }

  PostData(url, data: any) {
    return this.httpClient
      .post(this.apiendpoint + url, data)
      .pipe(catchError((error) => this.handleError(error)));
  }

  DeleteData(url) {
    return this.httpClient
      .delete(this.apiendpoint + url)
      .pipe(catchError((error) => this.handleError(error)));
  }

  GetReportData(url) {
    return this.httpClient
      .get(this.reportEndpoint + url)
      .pipe(catchError((error) => this.handleError(error)));
  }

  GetDownloadfile(url): Observable<any> {
    return this.httpClient
      .post(this.apiendpoint + url, { responseType: 'blob' as 'blob' })
      .pipe(catchError((error) => this.handleError(error)));
  }

  ErrorSave(url) {
    return this.httpClient.get(this.apiendpoint + url);
  }

  // DownloadFilePdf(url: any): any {
  //   return this.httpClient
  //     .get<Blob>(url, {
  //       headers: this.headers,
  //       responseType: "blob" as "json"
  //     })
  //     .pipe(catchError(error => this.handleError(error)));
  // }

  handleError(error) {
    const originalError = { ...error };
    if (error.status == 401) {
      this.route.navigateByUrl('/login');
    }
    console.error(error);
    // new Error(error);
    const errReport = <Observable<ResponseModel>>(
      this.ErrorSave(
        'ExceptionHandling/DataServiceErrorSave?message=' + error.message
      )
    );
    console.info(error);
    console.info(errReport);
    return originalError;
  }

  GetErrorMessage(error: HttpErrorResponse): any {
    switch (error.status) {
      case 500: {
        return 'Internal Server Error';
        //  break;
      }
      case 401: {
        return 'Unauthorized User';
        //  break;
      }
      case 404: {
        return 'Not Found';
        //  break
      }
      default: {
        return 'Internal Server Error';
      }
    }
  }

  DownloadFilePdf(url: any): any {
    return this.httpClient
      .get<Blob>(url, { headers: this.headers, responseType: 'blob' as 'json' })
      .pipe(catchError((error) => this.handleError(error)));
  }

  DownloadPdfPostMethod(url: any, data: any): any {
    return this.httpClient
      .post<Blob>(url, data, {
        headers: this.headers,
        responseType: 'blob' as 'json',
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  // DownloadFilePdf(url: any, data): any {
  //   return this.httpClient.post<Blob>(url, data,{headers: this.headers , responseType: 'blob' as 'json' })
  //   .pipe(catchError(error=> this.handleError(error)));
  // }

  // DownloadFilePdf(url: any): any {
  //   return this.httpClient.get<Blob>(this.reportBaseUrlPdf+url,{headers: this.headers , responseType: 'blob' as 'json' })
  //   .pipe(catchError(error=> this.handleError(error)));
  // }
  // DownloadFileXL(url: any): any {
  //   return this.httpClient.get<Blob>(this.reportBaseUrlXL+url,{headers: this.headers, responseType: 'blob' as 'json'  })
  //   .pipe(catchError(error=> this.handleError(error)));
  // }
}
